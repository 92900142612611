import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Container, Form, Button, InputGroup, Modal, Image } from 'react-bootstrap';
import WizardButtons from '../WizardButtons';
import { StepTwoProps, GuestInputProps, GuestProps } from '../../../interface';
import TomNJerry from '../../../img/tom-and-jerry.gif';

const GuestInput = ({guests, removeGuest, handleChange}: GuestInputProps) => {
  return (
    <div>
      {
        guests.map((guest, i) => (
          <Row style={{justifyContent: 'center'}} key={i}>
            <Col sm={10} md={8} lg={5}>
              <InputGroup>
                <Form.Control required type="text" placeholder="Full Name" id={i.toString()} onChange={(e) => handleChange(e)} value={guest.name ? `${guest.name}` : ''}/>
                {
                  i > 0 ? <Button variant="outline-danger" onClick={() => removeGuest(i)}>
                    Remove
                  </Button> : null
                }
              </InputGroup>
            </Col>
          </Row>
        ))
      }
    </div>
  )
}

const StepTwo = ({
  currentStep,
  attending,
  prevEventHandler,
  nextEventHandler,
  stepTwoValidated,
  guestAttending,
  handleStepTwoSubmit,
  setAttending,
  setGuestAttending,
  guestsAttending,
  setGuestsAttending,
  needDeleted,
  setNeedDeleted,
  groupId,
  mustGo
}: StepTwoProps) => {
  const [showMoreDetailModal, setShowMoreDetailModal] = useState(false);
  const [showMustBeHereModal, setShowMustBeHereModal] = useState(false);

    const _addGuest = () => {
      console.log('here');
      if (guestsAttending.length >= 4) {
        setShowMoreDetailModal(true);
      } else {
        const newGuest: GuestProps = {
          id: '',
          group_id: groupId ? groupId.toString() : '',
          first_name: '',
          last_name: '',
          date_time_responded: '',
          accepted: '1',
          name: '',
          phone: '',
          must_go: false
        };
        setGuestsAttending([...guestsAttending, newGuest]);
      }

    };

    const _removeGuest = (key: number) => {
      const removeGuest = guestsAttending[key];
      const newGuests = guestsAttending.filter((t, i) => i !== key);
      if (removeGuest.id) {
        setNeedDeleted([...needDeleted, removeGuest.id]);
      }

      setGuestsAttending(newGuests);

    }

    const _handleCheckBoxChange = (e: any) => {
      if (mustGo && e.target.value === 'false') {
        setShowMustBeHereModal(true);
      } else if (e.target.value === 'false') {
        console.log("setting to false");
        const newGuestsAttending = [...guestsAttending];
        for (let i = 0; i < newGuestsAttending.length; i++) {
          const guest = newGuestsAttending[i];
          guest.accepted = "0";
        }
        setGuestsAttending(newGuestsAttending);
        setAttending(false)
      } else {
        setAttending(e.target.value === "true")
      }
    }

    const _handleChange = (e: any) => {
      const id = e.target.id;
      const newGuestsAttending = [...guestsAttending];
      const guest = guestsAttending[id];
      guest.name = e.target.value;
      newGuestsAttending[id] = guest;
      console.log(newGuestsAttending);
      setGuestsAttending(newGuestsAttending);
    }

    if (currentStep === 2) {
      return (
        <Container className="text-center">
          <Modal
            show={showMoreDetailModal}
            //onHide={() => setShowMoreDetailModal(false)}
            backdrop="static"
            keyboard={false}
            size="lg"
            centered
          >
            <Modal.Body>
              <Container className="py-3 px-3 text-center">
                <h6>Maximum number of guests reached.</h6>
                <Button
                  className="rounded-circle round-button-sm"
                  variant="outline-dark"
                  onClick={() => setShowMoreDetailModal(false)}
                >
                  Close
                </Button>
              </Container>
            </Modal.Body>
          </Modal>
          <Modal
            show={showMustBeHereModal}
            //onHide={() => setShowMoreDetailModal(false)}
            backdrop="static"
            keyboard={false}
            size="lg"
            centered
          >
            <Modal.Body>
              <Container className="py-3 px-3 text-center">
                <h6>Bro, you <span className="text-danger">have</span> to be there.</h6>
                <div className='py-2' style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                  <Image
                    src={TomNJerry}
                    width="200"
                  />
                </div>
                <Button
                  className="rounded-circle round-button-sm"
                  variant="outline-dark"
                  onClick={() => setShowMustBeHereModal(false)}
                >
                  Close
                </Button>
              </Container>
            </Modal.Body>
          </Modal>
          <Row><h4>Will you be attending the wedding?</h4></Row>
          <Row>
            <Col>
              <Form.Check inline label="No" type='radio' id="attendingNo" name="attendingCheck" value="false" onChange={e => _handleCheckBoxChange(e)} checked={attending !== null && !attending}/>
              <Form.Check inline label="Yes" type='radio' id="attendingYes" name="attendingCheck" value="true" onChange={e => _handleCheckBoxChange(e)} checked={attending !== null && attending}/>
            </Col>
          </Row>
          <Form noValidate validated={stepTwoValidated} onSubmit={handleStepTwoSubmit}>
          {!attending ? null :
              <React.Fragment>
                  {/* <Form.Label column sm="4">How many are attending</Form.Label> 
                <Form.Group as={Row} controlId="validationCustom01" className="justify-content-md-center">
                  <Col sm="2">
                    <Form.Control value={guestAttending} as="select" type="select" onChange={e => setGuestAttending(e.target.value)} required>
                      <option value=""></option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="3">4</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                  */}
                <Row>
                  <h4>Who's coming along with you?</h4>
                </Row>
                <Row style={{justifyContent: 'center'}}>
                  <GuestInput guests={guestsAttending} removeGuest={_removeGuest} handleChange={_handleChange}/>
                  <Col sm={10} md={8} lg={5}>
                    <Button variant="outline-dark" style={{width: '100%'}} onClick={_addGuest}>
                      Add Guest
                    </Button>
                  </Col>
                </Row>
              </React.Fragment>
            }
            <Container className="pt-2">
            {attending !== null ?
              <WizardButtons
                buttonsText={['Back', 'Continue']}
                firstButtonEvent={prevEventHandler}
                secondButtonEvent={nextEventHandler}
                secondButtonType={"submit"}
                /> : null
              }
            </Container>
            </Form>
        </Container>
      )
    } else {
      return null;
    }
}


export default StepTwo;