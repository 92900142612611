import React, { useEffect, useRef, useState } from 'react';
import { useSpring, config, animated } from "@react-spring/web";

const LetterK = () => {
  const letterKRef = useRef<SVGPathElement>(null);
  const [offset, setOffset] = useState<number>(0);
  const [flip, setFlip] = useState(false);

  const { x } = useSpring({
    //reset: true,
    //reverse: flip,
    from: { x: 0 },
    x: 1,
    delay: 200,
    config: config.default,
    //config: { mass: 1, tension: 120, friction: 560 },
    //onRest: () => setFlip(!flip),
  })

  useEffect(() => {
    if (letterKRef.current) {
      setOffset(letterKRef.current.getTotalLength());
    }
  }, [letterKRef]);

  return (
    <animated.path
      className="cls-2"
      d="m682.52 547.11-6.22 3.41-4.6 5.92-1.33 6.56 1.78 6.23 3.11 5.77 5.63 4.45 8.15 3.85 12.59 1.33 13.63-2.22 19.55-9 18.67-13L790.37 525l24.3-32.15L879 398.22l63.41-93.78 17.59-19.7 14.81-13.33 11.86-8 9.77-3.85 8.3-1.73s9.78-2.18 9.63-1.09 6.52.3 6.52.3l6.37 1.63 3.75 4.14 1.58 5.49-1.58 4.89a21.51 21.51 0 0 1-7.75 9.48 157.4 157.4 0 0 1-23.11 12c-5.93 2.22-25.19 6.52-25.19 6.52l-19.11 3-29 2.52-65.92 1-39.93 2.66-31 4.59-31 9.19-19.85 13-9 9.18-5.92 10.82-2.82 12-.74 9.92.74 11.56 2.08 11.11"
      transform="translate(-665.23 -251.43)"
      //stroke='red'
      fill="transparent"
      strokeWidth="20"
      strokeDasharray={offset}
      ref={letterKRef}
      strokeDashoffset={x.to(x => (x) * offset)}
    />
  );
}

export default LetterK;