import React, { useEffect, useRef, useState } from 'react';
import { useSpring, config, animated } from "@react-spring/web";

const LetterKTwo = () => {
  const iconRef = useRef<SVGPathElement>(null);
  const [offset, setOffset] = useState<number>(0);
  const [flip, setFlip] = useState(false);

  const { x } = useSpring({
    //reset: true,
    reverse: flip,
    from: { x: 0 },
    x: 1,
    delay: 800,
    config: config.default,
    //config: { mass: 1, tension: 120, friction: 560 },
    //onRest: () => setFlip(!flip),
  })

  useEffect(() => {
    if (iconRef.current) {
      setOffset(iconRef.current.getTotalLength());
    }
  }, [iconRef]);

  return (
    <animated.path
      className="cls-2"
      d="m367.37 208.27-35.86 35.11-43.4 40.89-22.67 19.55-14.07 10.97-21.34 14.66-7.4 3.71h-8.89l-2.52-15.85 7.7-30.21 17.48-59.42-6.96-30.08-12.74-14.22 11.41-2.22 24.44-7.56 38.82-22.81 52-44 54.37-48.59 30.07-13.49 14.07-2.51 10.82.74 9.48 3.85"
      //stroke='red'
      fill="transparent"
      strokeWidth="18"
      strokeDasharray={offset}
      ref={iconRef}
      strokeDashoffset={x.to(x => (x) * offset)}
    />
  );
}

export default LetterKTwo;