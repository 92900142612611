import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Container, Form} from 'react-bootstrap';
import Input from 'react-phone-number-input/input';
import WizardButtons from '../WizardButtons';
import { StepThreeProps } from '../../../interface';

const StepThree = ({
    currentStep,
    setNotification,
    hasNotification,
    phoneNumber,
    updatePhoneNumber,
    prevEventHandler,
    handleStepThreeSubmit,
    guestsAttending,
    setGuestsAttending,
    nextEventHandler
}: StepThreeProps) => {
    const [validPhoneNumber, setValidPhoneNumber] = useState<boolean|null>(null);

    const _handleChange = (e: any) => {
      const newGuestsAttending = [...guestsAttending];
      const guest = guestsAttending[0];
      guest.phone = e;
      newGuestsAttending[0] = guest;
      console.log(newGuestsAttending);
      setGuestsAttending(newGuestsAttending);
      setValidPhoneNumber(e && e.length === 12);
    }

    const _handleStepThreeSubmit = () =>  {
      if (!hasNotification || (guestsAttending[0]?.phone && guestsAttending[0].phone.length === 12)) {
        setValidPhoneNumber(true);
        nextEventHandler();
      }
    }

    
    if (currentStep === 3) {
        return (
          <Container className="text-center">
            <Row>
              <h4>Can't wait to see you!</h4>
            </Row>
            <Row><p>Would you like to receive text message notifications on updates about the wedding?</p></Row>
            <Row>
              <Col>
                <Form.Check inline label="No" type='radio' id="attendingNo" name="attendingCheck" value="false" onChange={e => setNotification(e.target.value === "true")} checked={hasNotification !== null && !hasNotification}/>
                <Form.Check inline label="Yes" type='radio' id="attendingYes" name="attendingCheck" value="true" onChange={e => setNotification(e.target.value === "true")} checked={hasNotification !== null && hasNotification}/>
              </Col>
            </Row>
            {
              hasNotification ? 
              <Container>
                <Row className="pt-3">
                  <Col xs={12} md={6}>
                    <p>Please enter a phone number:</p>
                  </Col>
                  <Col xs={12} md={6}>
                  <Input
                    key="phoneInput"
                    country="US"
                    value={guestsAttending[0].phone}
                    maxLength={14}
                    onChange={_handleChange}
                    />
                  {
                    validPhoneNumber === null || validPhoneNumber === true ? null :
                      <p style={{color: 'red'}}>Required</p>
                  }
                  </Col>
                </Row>
              </Container> : null
            }
            <Container className='pt-1'>
              {
                hasNotification !== null ? 
                <WizardButtons
                  buttonsText={['Back', 'Continue']}
                  firstButtonEvent={prevEventHandler}
                  secondButtonEvent={_handleStepThreeSubmit}/> : null
              }
            </Container>
          </Container>
        );
    } else {
      return null;
    }
}

export default StepThree;