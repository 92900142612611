import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Container,Button} from 'react-bootstrap';
import { StepCompleteProps } from '../../../interface';

const StepComplete = ({
  currentStep,
  handleCloseModal,
  submitResult
}: StepCompleteProps) => {
  //Confirmation and submit data
  if (currentStep === 5) {
    return (
      <Container className="text-center">
        {
          submitResult ?
          <Row>
            <h5 className='text-success'>Submit Success</h5>
            <h6>Thank you for your RSVP we look forward to seeing you at our wedding!</h6>
            <Button onClick={handleCloseModal}>Close</Button> 
          </Row> :
          <Row>
            <h5 className='text-danger'>Submit Failed</h5>
            <h6>An error occured, please try again later.</h6>
            <Button onClick={handleCloseModal}>Close</Button>
          </Row>
        }
      </Container>
    );
  }
  return null;
}

export default StepComplete;