import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Container, Form } from 'react-bootstrap';
import WizardButtons from '../WizardButtons';
import { StepOneProps, GuestListProps, GuestProps } from '../../../interface';


const guestCheckBox = (guest: GuestProps) => {
  return `${guest.name}`;
}

const GuestList = ({guests, handleChange} : GuestListProps) => {
    const guestIds = Object.keys(guests);
    const lastKey = guestIds[guestIds.length - 1];
    let titles: any = {};
    
    guestIds.forEach(k => {
      let title = '';
      guests[k].forEach(guest => {
        title += !title ? guestCheckBox(guest) : ` + ${guestCheckBox(guest)}`;
      })
      titles[k] = title
    });
    console.log(titles);
    return (<div>
      {
        guestIds.map(key => (
          <Row style={{justifyContent: 'center'}} className="text-left" key={key}>
            <Col sm={8} md={6}>
              <Form.Check
                required
                inline
                type='radio'
                id='guest'
                name="guest"
                key={key}
                value={key}
                feedback={lastKey === key ? "Please select your name above." : ''}
                feedbackType="invalid"
                onChange={handleChange}
                label={titles[key]}
              />
            </Col>
          </Row>
        ))
      }
    </div>)
}


const StepOne = ({
  currentStep,
  guestName,
  prevEventHandler,
  nextEventHandler,
  guestLookUp,
  setGuestsAttending,
  setGroupId
}: StepOneProps) => {
    const [validation, setValidation] = useState<boolean>(false);

    const _handleSubmit = (event: any) => {
      console.log(event);
      const form = event.currentTarget;
      event.preventDefault();
      console.log(form.checkValidity());
      if (form.checkValidity() === false) {
        event.stopPropagation();
      } else {
        nextEventHandler();
        //setGuestsAttending
      }
      setValidation(true);
    }

    const _handleChange = (e: any) => {
      e.persist();
      console.log(e.target.value);
      let guests = guestLookUp[e.target.value];
      guests.forEach(guest => {
        guest.accepted = "1";
      })
      setGuestsAttending(guests);
      setGroupId(e.target.value);
    };

    return (
        currentStep === 1 ?
        <Container style={{justifyContent: 'center', alignSelf: 'center'}}>
          <h4 className="text-center">Please confirm your name below to continue.</h4>
          <Form noValidate validated={validation} onSubmit={_handleSubmit}>
            <GuestList guests={guestLookUp} handleChange={_handleChange}/>
            <div className="text-center">
              <WizardButtons
                buttonsText={['Back', 'Continue']}
                firstButtonEvent={prevEventHandler}
                secondButtonEvent={_handleSubmit}
                secondButtonType={"submit"}
              />
            </div>
          </Form>
        </Container> : null
    );
}

export default StepOne;