import React, { useEffect, useRef, useState } from 'react';
import { useSpring, config, animated } from "@react-spring/web";

const LetterQTwo = () => {
    const iconRef = useRef<SVGPathElement>(null);
    const [offset, setOffset] = useState<number>(0);
    const [flip, setFlip] = useState(false);
  
    const { x } = useSpring({
      //reset: true,
      reverse: flip,
      from: { x: 0 },
      x: 1,
      delay: 2000,
      config: config.slow,
      //config: { mass: 1, tension: 120, friction: 560 },
      //onRest: () => setFlip(!flip),
    })
  
    useEffect(() => {
      if (iconRef.current) {
        setOffset(iconRef.current.getTotalLength());
      }
    }, [iconRef]);

    return (
      <animated.path
        className="cls-2"
        d="m519.07 495.23-5.04 10.67-6.52 9.63-9.03 8.3-11.71 5.92-13.48 2.96-16-1.18-27.7-10.96-34.52-27.86-26.82-20.74-20-9.48-9.03-1.78H329l-10.67 2.97-6.37 3.7-5.33 9.63"
//        stroke="#4ADE80" // defines the colour of the "line"
        //strokeWidth="17" // defines the width of the "line"
        strokeWidth="10"
        strokeDasharray={offset}
        strokeDashoffset={x.to(x => (x) * offset)}
        ref={iconRef}
        //shapeRendering="crispEdges"
      />
    );
  }

  export default LetterQTwo;