import React, { useEffect, useRef, useState } from 'react';
import { Col, Nav, Navbar, NavDropdown, Row, Container, Offcanvas, Form, FormControl, Button, Image, Figure, Stack, Card, FloatingLabel, Modal } from 'react-bootstrap';

interface wizardButtonProps {
    buttonsText: string[],
    firstButtonEvent: () => void,
    secondButtonEvent: (arg: any) => void,
    secondButtonType?: "button" | "submit"
}

const WizardButtons = ({buttonsText, firstButtonEvent, secondButtonEvent, secondButtonType = "button"}:wizardButtonProps) => {
    return (
        <Row className="justify-content-center align-items-end pt-3">
        <Col>
            <Button
                variant="outline-dark"
                onClick={firstButtonEvent}
            >
                {buttonsText[0]}
            </Button>
        </Col>
        <Col>
            { secondButtonType === 'submit' ? 
            <Button
                variant="outline-dark"
                type={secondButtonType}
            >
                {buttonsText[1]}
            </Button> : 
            <Button
                variant="outline-dark"
                onClick={secondButtonEvent}
            >
                {buttonsText[1]}
            </Button>
            }
        </Col>
        </Row>
    )
}

export default WizardButtons;