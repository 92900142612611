import React, { useEffect, useRef, useState } from 'react';
import { useSpring, config, animated } from "@react-spring/web";

const LetterQ = () => {
    const iconRef = useRef<SVGPathElement>(null);
    const [offset, setOffset] = useState<number>(0);
    const [flip, setFlip] = useState(false);
  
    const { x } = useSpring({
      //reset: true,
      reverse: flip,
      from: { x: 0 },
      x: 1,
      delay: 1500,
      config: config.slow,
      //config: { mass: 1, tension: 120, friction: 560 },
      //onRest: () => setFlip(!flip),
    })
  
    useEffect(() => {
      if (iconRef.current) {
        setOffset(iconRef.current.getTotalLength());
      }
    }, [iconRef]);

    return (
      <animated.path
        className="cls-2"
        d="m462.18 274.34 4.89-9.92 8.3-11.26 9.18-8.95 8.59-3.35h8l6.37 3.35 4.28 6.88 1.21 9.77-1.21 11.26-4.42 15.26-9.49 22.67-13.63 25.56-22.07 34.44-23.55 29.77-18.08 19.12-18.37 17.77-21.48 17.93-21.47 14.07-21.49 11.41-18.82 6.82-18.96 2.96-12.15-1.48-10.52-8.74v-21.04l14.82-41.93 27.26-52.89 36.88-57.77 50.82-63.87 45.33-34.65 35.71-17.93 15.85-3.26 12-.59 9.63 1.25 7.11 3.34 3.57 5.48 1.32 6.23-1.32 8.44-6.24 9.04-9.33 8-11.41 5.93-9.63 2.96-23.66 5.21-24.49 2.58-22.51 1.54-46.23-2.66-54.22-3.86-52.44-.14-43.56 6.37L205 257.6l-12 12.74-8.45 14.82-3.7 17.33-.89 10.52 1.04 15.7"
//        stroke="#4ADE80" // defines the colour of the "line"
        //strokeWidth="17" // defines the width of the "line"
        strokeWidth="18"
        strokeDasharray={offset}
        strokeDashoffset={x.to(x => (x) * offset)}
        ref={iconRef}
        //shapeRendering="crispEdges"
      />
    );
  }

  export default LetterQ;