import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Container, ListGroup } from 'react-bootstrap';
import WizardButtons from '../WizardButtons';
import { StepFourProps, GuestLookProps } from '../../../interface';

const GuestList = ({guests}: GuestLookProps) => {
  return (
    <Row style={{justifyContent: 'center'}}>
      <Col sm={10} md={8} lg={5}>
        <ListGroup>
          {
            guests.map((guest, i) => (
              <ListGroup.Item key={i}>{guest.name}</ListGroup.Item>
            ))
          } 
        </ListGroup>
      </Col>
    </Row>
  )
}

const StepFour = ({
  currentStep,
  attending,
  hasNotification,
  prevEventHandler,
  handleStepFourSubmit,
  guestsAttending
}: StepFourProps) => {
  //Confirmation and submit data
  if (currentStep === 4) {
    return (
      <Container className="text-center">
        <Row>
          <h4>Please confirm the information</h4>
        </Row>
        <Row>
          <p>Attending: {attending ? 'YES' : 'NO'}</p>
          {
            attending && hasNotification ?
            <p>Phone Number: {guestsAttending[0].phone}</p> : null
          }
          {attending ? 
            <div>
              <h5>Guest(s) Attending</h5>
              <GuestList guests={guestsAttending}/>
            </div>
             : null
          }
        </Row>
        <WizardButtons
          buttonsText={['Back', 'Submit']}
          firstButtonEvent={prevEventHandler}
          secondButtonEvent={handleStepFourSubmit}/>
      </Container>
    );
  }
  return null;
}

export default StepFour;