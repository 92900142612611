import React, { useEffect, useRef, useState } from "react";
import QK9551 from "../img/QK_9776.jpg";
import QK9682 from "../img/QK_9659.jpg";
import QK9916 from "../img/QK_9916.jpg";
import graduationPhotoOne from "../img/QK_9274.jpg";
import QK9594 from "../img/QK_9594.jpg";
import graduationPhotoFour from "../img/QK_9551.jpg";
import QK9755 from "../img/QK_9755.jpg";
import QK9362 from "../img/QK_9362.jpg";
import QK9979 from "../img/QK_9979.jpg";
import QK9957 from "../img/QK_9957.jpg";
import QK8934 from "../img/QK_8934.jpg";
import QK9196 from "../img/QK_9196.jpg";
import QK9306 from "../img/QK_9306.jpg";
import QK9193 from "../img/QK_9131.jpg";
import QK9412 from "../img/QK_9412.jpg";
import QK9510 from "../img/QK_9510.jpg";
import dfw from "../img/dfw.jpeg";
import venue from "../img/venue2.jpeg";
import logo from "../img/weddingWebLogo.png";
import blueBackground from "../img/blue_background.jpeg";
import marriottLogo from "../img/mariott.jpg";
import homewoodLogo from "../img/homewoodSuite.jpeg";
import "./index.css";
import VenueMap from "./VenueMap";
import GalleryView from "./Gallery";
import Input from "react-phone-number-input/input";
import {
  Col,
  Nav,
  Navbar,
  Row,
  Container,
  Form,
  Button,
  Image,
  Stack,
  FloatingLabel,
  Modal,
} from "react-bootstrap";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import { useSpring, config } from "@react-spring/web";
import ImageGallery from "react-image-gallery";
import StepOne from "./Modal/StepOne";
import StepTwo from "./Modal/StepTwo";
import StepThree from "./Modal/StepThree";
import StepFour from "./Modal/StepFour";
import StepWrongUser from "./Modal/WrongUser";
import StepComplete from "./Modal/StepComplete";
import { GuestProps, GuestLookProps, PostObject } from "../interface";

const moreDetails: { [key: string]: any } = {
  ceremony: (
    <div>
      <h3 className="fancy-text-title theme-text">Our Ceremony</h3>
      <h4 className="pt-4">
        It celebrates the marriage and symbolizes a union of both families while
        giving the bride and groom an opportunity to honor their parents and
        ancestors.
      </h4>
      <h4 className="pt-4">
        Though we would love to have all of our guests to witness our ceremony,
        we will be holding an intimate tea ceremony in the morning with our
        families.
      </h4>
    </div>
  ),
  error: (
    <div>
      <h2 className="text-danger">Error</h2>
      <h4 className="pt-4">
        Please double check the name you entered or try to enter your first name only.
      </h4>
    </div>    
  )
};

const images = [
  {
    original: QK9412,
    originalHeight: 500,
  },
  {
    original: QK9362,
    originalHeight: 500,
  },
  {
    original: QK9979,
    originalHeight: 500,
  },
  {
    original: QK8934,
    originalHeight: 500,
  },
  {
    original: QK9193,
    originalHeight: 500,
  },
  {
    original: QK9196,
    originalHeight: 500,
  },
  {
    original: QK9510,
    originalHeight: 500,
  },
  {
    original: QK9306,
    originalHeight: 500,
  },
  {
    original: QK9755,
    originalHeight: 500,
  },
  {
    original: QK9551,
    originalHeight: 500,
  },
  {
    original: QK9916,
    originalHeight: 500,
  },
  {
    original: QK9682,
    originalHeight: 500,
  },
];

function MainPage() {
  const pageRefs = useRef({});
  const [show, setShow] = useState(false);
  const [showMoreDetailModal, setShowMoreDetailModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [detailModalBody, setDetailModalBoddy] = useState();
  let codeRef = useRef<any>(null);
  const [guestName, setGuestName] = useState<string>("");
  const [hasCompleted, setHasCompleted] = useState();
  const [guestId, setGuestId] = useState<number | null>(null);
  const [guestAttending, setGuestAttending] = useState<string>();
  const [guestsAttending, setGuestsAttending] = useState<GuestProps[]>([]);
  const [groupId, setGroupId] = useState<string | null>(null);
  const [qrValidated, setValidated] = useState<boolean>(false);
  const [stepTwoValidated, setStepTwoValidated] = useState<boolean>(false);
  const [stepThreeValidated, setStepThreeValidated] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [attending, setAttending] = useState<boolean | null>(null);
  const [submitResult, setSubmitResult] = useState<boolean | null>(null);
  const [hasNotification, setNotification] = useState<boolean | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<any>("");
  const [validPhoneNumber, setValidPhoneNumber] = useState<boolean | null>(
    null
  );
  const [mapOpacity, setMapOpacity] = useState<boolean>(true);
  const [guestLookUp, setGuestLookUp] = useState<GuestLookProps>({});
  const [needDeleted, setNeedDeleted] = useState<string[]>([]);
  const [navIsOpen, setNavIsOpen] = useState<boolean>(false);
  const [mustGo, setMustGo] = useState<boolean>(false);
  const [galleryPageSize, setGalleryPageSize] = useState<number>(0);
  let phoneNumberRef = useRef<any>();
  // @ts-ignore: Parameter 'type' implicitly has an 'any' type
  const scrollIntoView = (type) => {
    // @ts-ignore: Object is possibly 'null'.
    let startY = pageRefs.current[type].getBoundingClientRect().top;
    let navY = document.getElementById("responsive-navbar-nav")?.clientHeight;
    let viewY = document.getElementById("view")?.scrollTop;
    let screenBottom = document.getElementById("view")?.clientHeight;
    let y = navY ? startY + viewY - navY : startY + viewY;

    if (type === "home") {
      y = -4000;
    }
    if (type === "rsvp") {
      console.log("bottom:" + screenBottom);
      y = screenBottom ? screenBottom * 4 : y;
    } else if (document.getElementById("view")?.scrollTop === 0) {
      y = y / 2;
    } else if (startY < 0) {
      console.log("less: " + y);
      y = viewY ? (viewY + y) / 2 : y;
    } else if (startY > 0) {
      console.log("greater: " + y);
      y = viewY && navY ? startY / 2 + viewY : y;
    }
    if (y) {
      document.getElementById("view")?.scrollTo({
        top: y,
        behavior: "smooth",
      });
    }
    setNavIsOpen(false);
  };

  const handleShow = () => setShow(true);
  const handleCloseModal = () => {
    setAttending(null);
    setNotification(null);
    setGuestName("");
    setShow(false);
    setPhoneNumber(null);
    setGuestLookUp({});
    setNeedDeleted([]);
  };

  useEffect(() => {
    if (Object.keys(guestLookUp).length > 0 && !show) {
      console.log("open rsvp");
      handleShow();
    }
  }, [guestLookUp]);

  useEffect(() => {
    if (!show) {
      setCurrentStep(1);
    }
  }, [show]);

  useEffect(() => {
    if (submitResult !== null) {
      setCurrentStep(5);
    }
  }, [submitResult]);

  useEffect(() => {
    for (let i = 0; i < guestsAttending.length; i++) {
      const guest = guestsAttending[i];
      if (guest.must_go) {
        setMustGo(true);
        break;
      } 
      if (mustGo) {
        setMustGo(false);
      }
    }
  }, [guestsAttending])

  useEffect(() => {
    if (window.innerWidth < 500) {
      setGalleryPageSize(36);
    } else if (window.innerWidth > 1500) {
      setGalleryPageSize(10);
    } else if (window.innerWidth > 1100) {
      setGalleryPageSize(18);
    } else if (window.innerWidth > 900) {
      setGalleryPageSize(18);
    }else {
      setGalleryPageSize(21);
    }
  }, []);

  const getGuest = async () => {
    console.log(codeRef.current.value);
    let encodedCode = encodeURIComponent(codeRef.current.value);

    await fetch(
      `https://0ba347iv30.execute-api.us-east-1.amazonaws.com/guest?name=${encodedCode}`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (Object.keys(data).length > 0 && !data.hasOwnProperty("message")) {
          console.log(data.first_name);
          setGuestLookUp(data);
        } else {
          console.log(data.message);
          setDetailModalBoddy(moreDetails['error']);
          setShowMoreDetailModal(true);
        }
      })
      .catch((e) => {
        console.log("Not found");
        //console.log(e);
        setDetailModalBoddy(moreDetails['error']);
        setShowMoreDetailModal(true);
      });
  };

  const postRsvp = async () => {
    let params: PostObject = {
      guests: guestsAttending,
      toDelete: needDeleted,
      groupId: groupId,
    };

    console.log(params);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    };

    await fetch(
      "https://0ba347iv30.execute-api.us-east-1.amazonaws.com/rsvp",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setSubmitResult(true);
        _next();
      })
      .catch((e) => {
        console.log(e);
        setSubmitResult(false);
        _next();
      });
  };

  const handleGuestSubmit = (event: any) => {
    console.log(event);
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      getGuest();
    }
    setValidated(true);
  };

  const _prev = () => {
    if (currentStep >= 1) {
      setCurrentStep(currentStep - 1);
    }

    if (currentStep === 4 && !attending) {
      setCurrentStep(2);
    }
  };

  const _next = () => {
    if (currentStep <= 4) {
      if (currentStep === 2 && !attending) {
        setCurrentStep(currentStep + 2);
      } else {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  const _handleStepTwoSubmit = (event: any) => {
    console.log(event);
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      _next();
    }
    setStepTwoValidated(true);
  };

  const _handleStepThreeSubmit = () => {
    if (!hasNotification || validPhoneNumber) {
      _next();
    }
  };

  const updatePhoneNumber = (e: any) => {
    console.log(e);
    setPhoneNumber(e);
    setValidPhoneNumber(e && e.length === 12);
  };

  const showMoreInfoModal = (type: any) => {
    if (moreDetails[type]) {
      setDetailModalBoddy(moreDetails[type]);
      setShowMoreDetailModal(true);
    }
  };

  const [flip, set] = useState(false);

  return (
    <Container style={{ padding: 0 }} fluid>
      <Modal
        show={show}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Body>
          <StepOne
            currentStep={currentStep}
            guestName={guestName}
            prevEventHandler={() => _prev()}
            nextEventHandler={() => _next()}
            guestLookUp={guestLookUp}
            setGuestsAttending={setGuestsAttending}
            setGroupId={setGroupId}
          />
          {StepTwo({
            currentStep: currentStep,
            attending: attending,
            prevEventHandler: _prev,
            nextEventHandler: _next,
            stepTwoValidated: stepTwoValidated,
            guestAttending: guestAttending,
            handleStepTwoSubmit: _handleStepTwoSubmit,
            setAttending: setAttending,
            setGuestAttending: setGuestAttending,
            guestsAttending: guestsAttending,
            setGuestsAttending: setGuestsAttending,
            needDeleted: needDeleted,
            setNeedDeleted: setNeedDeleted,
            groupId: groupId,
            mustGo: mustGo
          })}
          {StepThree({
            currentStep: currentStep,
            setNotification: setNotification,
            hasNotification: hasNotification,
            phoneNumber: phoneNumber,
            updatePhoneNumber: updatePhoneNumber,
            prevEventHandler: _prev,
            handleStepThreeSubmit: _handleStepThreeSubmit,
            guestsAttending: guestsAttending,
            setGuestsAttending: setGuestsAttending,
            nextEventHandler: _next,
          })}
          {StepFour({
            currentStep: currentStep,
            attending: attending,
            hasNotification: hasNotification,
            prevEventHandler: _prev,
            handleStepFourSubmit: postRsvp,
            guestsAttending: guestsAttending,
          })}
          <StepWrongUser
            currentStep={currentStep}
            handleCloseModal={handleCloseModal}
          />
          <StepComplete
            currentStep={currentStep}
            handleCloseModal={handleCloseModal}
            submitResult={submitResult}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={showMoreDetailModal}
        //onHide={() => setShowMoreDetailModal(false)}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Body>
          <Container className="py-3 px-3 text-center">
            {detailModalBody}
            <Button
              className="rounded-circle round-button p-3"
              variant="outline-dark"
              onClick={() => setShowMoreDetailModal(false)}
            >
              Close
            </Button>
          </Container>
        </Modal.Body>
      </Modal>
      <Navbar
        style={{ padding: 0 }}
        expanded={navIsOpen}
        expand="lg"
        variant="light"
        className="sticky-navbar"
        sticky="top"
        onToggle={(e) => {
          console.log(e);
          setNavIsOpen(e);
        }}
      >
        <Navbar.Brand href="#home">
          <Image
            src={logo}
            width="100"
            //height="150"
            //className="d-inline-block align-bottom"
            className="main-logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="text-center">
          <Nav
            className="mx-auto"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Nav.Link onClick={() => scrollIntoView("home")} href="#">
              HOME
            </Nav.Link>
            {/*}
            <Nav.Link onClick={() => scrollIntoView("ceremony")}>
              CEREMONY
            </Nav.Link>
            {/*
            <Nav.Link onClick={() => scrollIntoView("ourStory")}>
              OUR STORY
            </Nav.Link>
*/
            }
            <Navbar.Brand href="#home">
              <Image
                src={logo}
                width="220"
                height="120"
                //className="d-inline-block align-bottom"
                className="collapse-logo"
                alt="React Bootstrap logo"
              />
            </Navbar.Brand>
            <Nav.Link onClick={() => scrollIntoView("gallery")}>
              GALLERY
            </Nav.Link>
            {/*
            <Nav.Link onClick={() => scrollIntoView("reception")}>
              RECEPTION
            </Nav.Link>
            <Nav.Link onClick={() => scrollIntoView("accommodation")}>
              ACCOMMODATION
            </Nav.Link>
            <Nav.Link onClick={() => scrollIntoView("todoEat")}>
              TO DO/TO EAT
            </Nav.Link>
            <Nav.Link onClick={() => scrollIntoView("rsvp")}>RSVP</Nav.Link>
          */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
{
  !galleryPageSize ? null : 
  <Parallax
        id="view"
        pages={galleryPageSize}
        style={{
          top: "0",
          left: "0",
          //backgroundImage: `url(${graduationPhotoFour}), url(${notAvailablePhoto})`,
          backgroundImage: `url(${graduationPhotoFour})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <ParallaxLayer
          offset={0}
          speed={0}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            backgroundImage: `url(${graduationPhotoOne})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div className="head-text">
            <div
              className="page-anchor"
              id="home"
              ref={(el) =>
                (pageRefs.current = { ...pageRefs.current, home: el })
              }
            />
            <div className="head-image"></div>
          </div>
          {
            /*
          <div className="text-on-image">
            K & Q <br /> 08.27.22
          </div>
            */
          }
        </ParallaxLayer>

        <ParallaxLayer
          offset={1}
          speed={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            //color: 'white',
            backgroundColor: "transparent",
          }}
        >
          <Stack>
            <Container
              style={{
                backgroundImage: `url(${blueBackground})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
              fluid
            >
              <Row className="align-items-center justify-content-center">
                <Col xs="12" md="8" className="text-center py-5">
                  <div
                    className="py-5 px-5"
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      borderWidth: "10px",
                      borderStyle: "solid",
                      borderColor: "#7d808261",
                    }}
                  >
                    <h4 className="pt-4 text-center">
                      "A successful marriage requires falling in love many
                      times, always with the same person."
                    </h4>
                    <h4 className="theme-text">– Mignon McLaughlin</h4>
                  </div>
                </Col>
              </Row>
            </Container>
            <Container
              fluid
              style={{
                backgroundColor: 'white',
              }}
            >
              <div
                className="page-anchor"
                id="gallery"
                ref={(el) =>
                  (pageRefs.current = { ...pageRefs.current, gallery: el })
                }
              />
              <GalleryView/>
              <div className="fancy-text" 
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                <h6>
                  Credit:&nbsp;
                  <a
                    href="https://www.victoriaaliu.com/"
                    target="_blank"
                    rel="noreferrer noopener"
                    style={{color: 'black'}} 
                  >
                    Victoria Liu Photography
                  </a>
                </h6>
              </div>
            </Container>
            {
              /*
            <Row className="align-items-center justify-content-center text-column">
              <div
                className="page-anchor"
                id="ceremony"
                ref={(el) =>
                  (pageRefs.current = { ...pageRefs.current, ceremony: el })
                }
              />
              <Col xs="12" md="12" className="text-center">
                <div className="py-5">
                  <h1 className="fancy-text-title">Our Ceremony</h1>
                  <Container className="text-center">
                    <Row className="px-2">
                      <h4>
                        In Vietnamese culture, the traditional tea ceremony is
                        considered a proper meeting of the bride and groom’s
                        families.
                      </h4>
                      <div>
                        <Button
                          className="rounded-circle round-button p-3"
                          variant="outline-light"
                          onClick={() => showMoreInfoModal("ceremony")}
                        >
                          More
                        </Button>
                      </div>
                    </Row>
                  </Container>
                </div>
              </Col>
              {/*
                          <Col xs="1" md="6" lg="6" className="row-image d-none d-lg-block" style={{ backgroundImage: `url(${notAvailablePhoto})`, backgroundPosition: 'center', backgroundSize: 'cover'}}>
            </Col>
              */}
            {/*</Row>*/}
            {/*
            <Row className="align-items-center justify-content-center text-column">
              <div
                className="page-anchor"
                id="ourStory"
                ref={(el) =>
                  (pageRefs.current = { ...pageRefs.current, ourStory: el })
                }
              />
              {/*
                          <Col xs="6" md="6" lg="6" className="row-image d-none d-lg-block" style={{ backgroundImage: `url(${nonSmilePhoto})`, backgroundPosition: 'center', backgroundSize: 'cover'}}>
              
            </Col>
              }
              <Col xs="12" md="12" className="text-center py-5">
                <Row className="px-5">
                  <h1 className="fancy-text-title">Our Story</h1>
                  <h4 className="pt-4 text-center">
                    In 2017 we met each other at a friends wedding. Quynh fell
                    in love at first site and now we are here.
                  </h4>
                  <div>
                    <Button
                      className="rounded-circle round-button p-3"
                      variant="outline-light"
                      onClick={() => showMoreInfoModal("ourStory")}
                    >
                      More
                    </Button>
                  </div>
                </Row>
              </Col>
            </Row>*/}
            {/*
            <Container
              style={{
                padding: "0px",
                backgroundColor: "white",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
              fluid
            >
              <div
                className="page-anchor"
                id="gallery"
                ref={(el) =>
                  (pageRefs.current = { ...pageRefs.current, gallery: el })
                }
              />
              <ImageGallery
                items={images}
                showThumbnails={false}
                autoPlay={true}
                showPlayButton={false}
                showBullets={true}
                showFullscreenButton={false}
                slideDuration={1000}
              />
              <div className="fancy-text" 
              style={{
                justifyContent: 'center',
                display: 'flex',
                }}>
                <h6>
                  Credit:&nbsp;
                  <a
                    href="https://www.victoriaaliu.com/"
                    target="_blank"
                    rel="noreferrer noopener"
                    style={{color: 'black'}} 
                  >
                    Victoria Liu Photography
                  </a>
                </h6>
              </div>
            </Container>
            <Container
              className="align-items-center justify-content-center text-column"
              fluid
              style={{
                backgroundImage: `url(${blueBackground})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              <div
                className="page-anchor"
                id="accommodation"
                ref={(el) =>
                  (pageRefs.current = {
                    ...pageRefs.current,
                    accommodation: el,
                  })
                }
              />
              <Container className="text-center py-3">
                <Row className="text-center py-5">
                  <h1 className="fancy-text-title text-dark">Accommodations</h1>
                  <h4 className="text-center text-dark" style={{ fontFamily: "'Playfair Display', serif" }}>
                    For your convenience, a preferred rate is available the
                    weekend of our wedding, August 26st - August 28th.
                  </h4>
                  <h5 style={{ fontFamily: "'Playfair Display', serif" }} className="text-dark">Reserve a room online or call in and refer to <b>Nguyen Pham Wedding Room Block</b>.</h5>
                </Row>
                <Row className="px-1" style={{ fontFamily: "'Playfair Display', serif" }}>
                  <Col xs={6} className="justify-content-center">
                    <Row className="justify-content-center">
                      <Col xs={12} md={8}>
                        <a
                          href="https://www.marriott.com/events/start.mi?id=1650902796275&key=GRP"
                          target="_blank"
                          className="places-link"
                          rel="noreferrer noopener"
                        >
                          <Image src={marriottLogo} fluid={true} />
                        </a>
                        <label className="text-dark">
                          <a
                            href="https://www.marriott.com/events/start.mi?id=1650902796275&key=GRP"
                            target="_blank"
                            className="text-dark"
                            rel="noreferrer noopener"
                          >
                            <b>Reserve Room Online</b>
                          </a>
                        </label>
                        <label className="text-dark">Phone Number: <b>800.228.9290</b></label>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={6} className="justify-content-center">
                    <Row className="justify-content-center">
                      <Col xs={12} md={8}>
                        <a
                          href="https://www.hilton.com/en/attend-my-event/ftwmchw-pnw-83e6b343-bf81-42bd-a0ea-85a8d49c5c27/"
                          target="_blank"
                          className="places-link"
                          rel="noreferrer noopener"
                        >
                          <Image src={homewoodLogo} fluid={true} />
                        </a>
                        <label className="text-dark">
                          <a
                            href="https://www.hilton.com/en/attend-my-event/ftwmchw-pnw-83e6b343-bf81-42bd-a0ea-85a8d49c5c27/"
                            target="_blank"
                            className="text-dark"
                            rel="noreferrer noopener"
                          >
                            <b>Reserve Room Online</b>
                          </a>
                        </label><br/>
                        <label className="text-dark">Phone Number: <b>817.921.0202</b></label>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </Container>
              */}
              {/*
            <Container
              style={{ height: "700px", width: "100%", padding: 0 }}
              fluid
            >
              <Row className="align-items-center justify-content-center text-column">
                <div
                  className="page-anchor"
                  id="reception"
                  ref={(el) =>
                    (pageRefs.current = { ...pageRefs.current, reception: el })
                  }
                />
                <VenueMap />
                <div
                  className="text-center py-4"
                  style={{
                    //backgroundColor: "#5452528c",
                    backgroundImage: `url(${venue})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    display: mapOpacity ? "flex" : "none",
                    position: "absolute",
                    height: "700px",
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                >
                  <h2 className="text-center fancy-text-title">Reception</h2>
                  <h6
                    className="text-center fancy-text-title"
                    style={{ fontSize: "30px" }}
                  >
                    08|27|22
                  </h6>

                  <h3 className="text-center">Stoney Ridge Villa</h3>
                  <a
                    target="_blank"
                    className="places-link"
                    href="https://www.google.com/maps/search/?api=1&query=stoney+ridge+villa"
                    rel="noreferrer noopener"
                  >
                    <h5 style={{ fontFamily: "'Playfair Display', serif" }}>
                      451 Stoney Ridge, <br /> Azle, TX 76020
                    </h5>
                  </a>
                  <p className="pt-2" style={{ fontFamily: "'Playfair Display', serif" }}>
                    Cocktail Hour starts at 5:30 PM
                  </p>
                  <p style={{ fontFamily: "'Playfair Display', serif" }}>
                    Reception to follow at 6:30 PM
                  </p>
                  <p style={{ fontFamily: "'Playfair Display', serif" }}>
                    Dress Code: Formal
                  </p>
                </div>
                <div
                  className="text-center"
                  style={{
                    //backgroundColor: '#000000b5',
                    position: "absolute",
                    height: "10px",
                    transform: "translate(0%, 2050%)",
                    fontFamily: "'Playfair Display', serif"
                  }}
                >
                  <Button
                    className="rounded-circle"
                    variant="outline-light"
                    style={{
                      height: '75px',
                      width: '75px',
                      backgroundColor: 'white',
                      color: 'black'
                    }}
                    onClick={() => setMapOpacity(!mapOpacity)}
                  >
                    {mapOpacity ? (
                      <h6 style={{ marginBottom: 0 }}>
                        View
                        <br />
                        Map
                      </h6>
                    ) : (
                      "Details"
                    )}
                  </Button>
                </div>
              </Row>
            </Container>
            {/*
            <Row
              className="align-items-center justify-content-center text-column text-center"
              style={{
                backgroundImage: `url(${dfw})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              <div
                className="page-anchor"
                id="todoEat"
                ref={(el) =>
                  (pageRefs.current = { ...pageRefs.current, todoEat: el })
                }
              />
              <div className="py-4" style={{ backgroundColor: "#5452528c" }}>
                <Container>
                  <h1 className="fancy-text-title">Things To Do/Eat</h1>
                  <h4>
                    Here are some suggestions that we think you might enjoy while you
                    are visiting sunny DFW.
                  </h4>
                </Container>
                <Row>
                  <Col xs="12" md="6" className="text-center">
                    <div className="pb-1">
                      <h2 className="pt-5">Things To Do</h2>
                      <p>
                        <a
                          href="https://www.fortworthzoo.org/"
                          target="_blank"
                          className="places-link"
                          rel="noreferrer noopener"
                        >
                          Fort Worth Zoo
                        </a>
                      </p>
                      <p>
                        <a
                          href="https://dwazoo.com/"
                          target="_blank"
                          className="places-link"
                          rel="noreferrer noopener"
                        >
                          The Dallas World Aquarium
                        </a>
                      </p>
                      <p>
                        <a
                          href="https://www.perotmuseum.org/"
                          target="_blank"
                          className="places-link"
                          rel="noreferrer noopener"
                        >
                          Perot Museum of Nature and Science
                        </a>
                      </p>
                      <p>
                        <a
                          href="https://www.sixflags.com/overtexas"
                          target="_blank"
                          className="places-link"
                          rel="noreferrer noopener"
                        >
                          Six Flags
                        </a>
                      </p>
                      <p>
                        <a
                          href="https://fwbg.org/"
                          target="_blank"
                          className="places-link"
                          rel="noreferrer noopener"
                        >
                          Fort Worth Botanic Garden
                        </a>
                      </p>
                    </div>
                  </Col>
                  <Col xs="12" md="6" className="text-center">
                    <div className="pb-1">
                      <h2 className="pt-5">Things To Eat</h2>
                      <p>
                        <a
                          href="https://www.dongquetexas.com/"
                          target="_blank"
                          className="places-link"
                          rel="noreferrer noopener"
                        >
                          Đồng Quê
                        </a>
                      </p>
                      <p>
                        <a
                          href="http://www.aribbq.com/"
                          target="_blank"
                          className="places-link"
                          rel="noreferrer noopener"
                        >
                          Ari Korean BBQ
                        </a>
                      </p>
                      <p>
                        <a
                          href="http://haidilaofriscoreserve.com/"
                          target="_blank"
                          className="places-link"
                          rel="noreferrer noopener"
                        >
                          HaiDiLao Hot Pot
                        </a>
                      </p>
                      <p>
                        <a
                          href="http://www.phopalace.com/#/"
                          target="_blank"
                          className="places-link"
                          rel="noreferrer noopener"
                        >
                          Pho Palace
                        </a>
                      </p>
                      <p>
                        <a
                          href="http://www.gyu-kaku.com/"
                          target="_blank"
                          className="places-link"
                          rel="noreferrer noopener"
                        >
                          Gyu-Kaku Japanese BBQ
                        </a>
                      </p>
                      <p>
                        <a
                          href="https://www.rodeogoat.com/"
                          target="_blank"
                          className="places-link"
                          rel="noreferrer noopener"
                        >
                          Rodea Goat
                        </a>
                      </p>
                      <p>
                        <a
                          href="https://www.yelp.com/biz/hatsuyuki-handroll-bar-fort-worth-2"
                          target="_blank"
                          className="places-link"
                          rel="noreferrer noopener"
                        >
                          Hatsuyuki Handroll Bar
                        </a>
                      </p>
                      <p>
                        <a
                          href="https://www.thecookshack.com/"
                          target="_blank"
                          className="places-link"
                          rel="noreferrer noopener"
                        >
                          The Cookshack
                        </a>
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Row>
            */}
          </Stack>
        </ParallaxLayer>
        {/*
        <ParallaxLayer
          offset={4}
          speed={0.5}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
          }}
        >
          <Container fluid>
            <h2 className="text-center pb-4 pt-3 fancy-text">Please Enter Your First Name</h2>
            <Form
              onSubmit={handleGuestSubmit}
            >
              <Row className="justify-content-center">
                <Col xs="10" sm="8" md="6">
                  <FloatingLabel controlId="floatingCode" label="Name">
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      className="fs-1"
                      ref={codeRef}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid name.
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>
              <Row className="justify-content-center py-3">
                <Col
                  xs={12}
                  style={{display: 'flex'}}
                  className="justify-content-center py-3"
                >
                  <Button
                    type="submit"
                    className="rounded-circle round-button"
                    variant="outline-dark"
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
          <div
            className="page-anchor"
            id="rsvp"
            ref={(el) => (pageRefs.current = { ...pageRefs.current, rsvp: el })}
          />
        </ParallaxLayer>
        */}
      </Parallax>
}
      
    </Container>
  );
}

export default MainPage;
