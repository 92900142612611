import React, { useEffect, useRef, useState } from 'react';
import { Gallery, Image } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";


function GalleryView()
{
  const [images, setImages] = useState<Image[]>([]);
  const [index, setIndex] = useState(-1);

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index: number) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  useEffect(() => {
    getImageList();
  }, []);

  const getImageList = async () => {
    await fetch(
      `https://0ba347iv30.execute-api.us-east-1.amazonaws.com/wedding-image-list`
    )
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        //setImageKeys(JSON.parse(data));
        const imageMetaData: Image[] = [];
        for (let i = 0; i < data.length; i++) {
          const imageData: Image = {
            src: "https://0ba347iv30.execute-api.us-east-1.amazonaws.com/gallery-image?id=" + data[i].name,
            width: data[i].width / 5,//data[i].width,
            height: data[i].height / 5
          }
          imageMetaData.push(imageData);
        }
        setImages(imageMetaData);
      })
      .catch((e) => {
        console.log("Not found");
      });
  }
  
  return(
    <div>
      <Gallery 
        images={images}
        rowHeight={300}
        onClick={handleClick}
        enableImageSelection={false}
      />
      {!!currentImage && (
        /* @ts-ignore */
        <Lightbox
          mainSrc={ "https://0ba347iv30.execute-api.us-east-1.amazonaws.com/gallery-image?original=1&id=" + currentImage.src.split("?id=")[1] }
          imageTitle={currentImage.caption}
          mainSrcThumbnail={currentImage.src}
          nextSrc={ "https://0ba347iv30.execute-api.us-east-1.amazonaws.com/gallery-image?original=1&id=" + currentImage.src.split("?id=")[1] }
          nextSrcThumbnail={nextImage.src}
          prevSrc={ "https://0ba347iv30.execute-api.us-east-1.amazonaws.com/gallery-image?original=1&id=" + currentImage.src.split("?id=")[1] }
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
    </div>
  );
}


export default React.memo(GalleryView);