import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import './App.css';
import MainPage from './MainPage';
import { useTransition, config, animated, AnimatedProps } from "@react-spring/web";
import LoadingIcon from './LoadingPage';


const pages: ((props: AnimatedProps<{ style: CSSProperties }>) => React.ReactElement)[] = [
  ({style}) => <animated.div style={style}><LoadingIcon/></animated.div>,
  ({style}) => <animated.div style={style}><MainPage/></animated.div>,
];

function App() {
  const [ isLoading, setIsLoading ] = useState(true);
  const [index, setIndex] = useState(0);

  const transitions = useTransition(index, {
    keys: null,
    from: { opacity: 0},
    enter: { opacity: 1},
    leave: { opacity: 0},
    config: { duration: 1500 },
    onRest: (_a, _b, item) => {
      if (index === item && index < pages.length - 1) {
        setIndex(state => (state + 1) % pages.length)
      }
    },
    exitBeforeEnter: true,
  })

  useEffect(() => {
    setTimeout(() => (setIsLoading(false)), 5000)
  }, []);

  return (
    <div>
      {transitions((style, i) => {
        const Page = pages[i];
        return <Page style={style}/>
      })}
    </div>
  );

  return (
    <MainPage/>
  );
}

export default App;
