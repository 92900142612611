import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Container,Button} from 'react-bootstrap';

interface stepWrongUserProps {
  currentStep: number,
  handleCloseModal: () => void
}

const StepWrongUser = ({
  currentStep,
  handleCloseModal
}: stepWrongUserProps) => {
  //Confirmation and submit data
  if (currentStep === 0) {
    return (
      <Container className="text-center">
      <Row>
        <p>Please, double check the name you've enter and try again.</p>
      </Row>
      <Row className="justify-content-md-center">
        <Col md={3}>
          <Button variant="dark" onClick={handleCloseModal}>OK</Button>
        </Col>
      </Row>
    </Container> 
    );
  }
  return null;
}

export default StepWrongUser;